import { useEffect, useMemo } from "react";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import initializeDatadogRum from "./utils/initializeDatadogRum";
import initializeDatadogLogs from "./utils/initializeDatadogLogs";
import { PropTypes } from "prop-types";

const DatadogWrapper = ({ children }) => {
  const { authToken } = useAuthContext();
  const enableDatadogLogs = window.feConfig.DATADOG_LOGS_ENABLED;
  const { email, userId } = useMemo(() => {
    const parsedAuthToken = JSON.parse(atob(authToken.split(".")[1]));
    return { email: parsedAuthToken?.email, userId: parsedAuthToken?.sub };
  }, [authToken]);

  useEffect(() => {
    if (email && userId) {
      initializeDatadogRum(userId, email);
    }
  }, [email, userId]);

  useEffect(() => {
    if (enableDatadogLogs) {
      initializeDatadogLogs();
    }
  }, [enableDatadogLogs]);

  return <>{children}</>;
};

DatadogWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DatadogWrapper;
