import React from "react";
import PayerForm from "components/Forms/PayerForm";
import { useCreatePayer } from "api/core/payerHooks";
import { useNavigate } from "react-router";

const CreatePayer = () => {
  const navigate = useNavigate();
  const { createRecord: createPayer } = useCreatePayer();

  const redirectUrl = (response) => {
    const payerId = response?.data?.data?.id;
    return payerId ? `/payers/${payerId}` : "/payers";
  };

  const handleSubmit = async (values) => {
    try {
      const response = await createPayer(values);
      navigate(redirectUrl(response));
    } catch (error) {
      console.error("Error creating payer: ", error);
    }
  };
  return <PayerForm onSubmit={handleSubmit} />;
};

export default CreatePayer;
