export const setPlanTabLocation = (location) => {
  localStorage.setItem("planTabLocation", location);
};

export const getPlanTabLocation = () => {
  return localStorage.getItem("planTabLocation");
};

export const setUsersFilters = (filters) => {
  const filtersString = JSON.stringify(filters);
  localStorage.setItem("usersFilters", filtersString);
};

export const getUsersFilters = () => {
  const usersFilters = localStorage.getItem("usersFilters");
  if (usersFilters && usersFilters !== "undefined")
    return JSON.parse(localStorage.getItem("usersFilters"));
  return {};
};
