import React, { Component } from "react";
import { Icon, Button } from "@unite-us/app-components";
import { DefaultLayout } from "layouts/DefautLayout";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error Boundary Caught an Error", error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false });
    }
  }

  handleReturnToPreviousPage = () => {
    if (this.props.navigate) {
      this.props.navigate(-1);
    } else {
      this.props.navigate("/");
    }
  };

  render() {
    if (this.state.hasError) {
      return (
        <DefaultLayout>
          <div className="h-full w-full flex justify-center items-center">
            <div className="text-center">
              <Icon icon="Warning" size={80} color="text-blue" />
              <h1 className=" text-2xl font-semibold py-2">
                Something went wrong.
              </h1>
              <p className="text-base pb-5">
                Go back and try again later. If problem persists, please contact
                support.
              </p>
              <Button
                outline
                label="Return to previous page"
                className="normal-case"
                onClick={this.handleReturnToPreviousPage}
              />
            </div>
          </div>
        </DefaultLayout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
